import React from "react";

import SEO from "../components/seo";
import Layout from "../components/Layout";
import ContatoSuccess from "../components/ContatoSuccess";

const ContatoSuccessPage = () => {
  return (
    <Layout>
      <SEO title="Mensagem enviada com Sucesso!" />
      <ContatoSuccess />
    </Layout>
  );
};

export default ContatoSuccessPage;
