import React from "react";

import NavMenu from "../NavMenu";
import Footer from "../Footer";
import Cta from "./CtaSend";

import { Container } from "./styles";

const ContatoPage = () => {
  return (
    <>
      <NavMenu />
      <Container>
        <h2>Sua Mensagem foi envida com sucesso!</h2>
        <h3>Em breve retornaremos. Obrigado pelo contato!</h3>
        <Cta>Voltar a Home</Cta>
      </Container>
      <Footer />
    </>
  );
};

export default ContatoPage;
