import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  margin-top: 72px;
  margin-bottom: 100px;
  padding: 0 32px;
  text-align: center;

  h2 {
    font-size: 32px;
    font-weight: bold;
    margin-top: 64px;

    @media (max-width: 885px) {
      font-size: 28px;
    }

    @media (max-width: 450px) {
      font-size: 22px;
      margin-bottom: 28px;
    }
  }

  h3 {
    font-size: 22px;
    color: #555;
    font-weight: bold;
    margin: 16px 0 40px 0;
  }
`;
